import React, {Component} from "react"
import "../css/header.css"
import logoUrl from '../images/home/logo.png'

class HeaderContent extends Component{

	constructor() {
		super();
		this.state = {
			logo_hide: false
		};
	}

	changeMenuActive = (e) => {
		const {changeMenu} = this.props;
		const active_index = e.target.getAttribute('data-index')
		changeMenu(active_index)
	}

	componentDidMount(){
		if(document.body.clientWidth <= 737){
			this.setState({
				logo_hide: true
			})
		}else{
			this.setState({
				logo_hide: false	
			})
		}
		window.addEventListener('resize', this.handleResize.bind(this))
	}
	handleResize = (e) => {
		if(document.body.clientWidth <= 737){
			this.setState({
				logo_hide: true
			})
		}else{
			this.setState({
				logo_hide: false	
			})
		}
	}
	render(){
		const {menu_list, active_menu} = this.props;
		const listItems = menu_list.map((numbers, keys) =>
  			<li data-index={keys} onClick={this.changeMenuActive} className={active_menu==keys?'menu_li active hand_point':'menu_li hand_point'} key={keys}>{numbers}</li>
		);

		return(
		<div id="header">
			<div id="logo" className={this.state.logo_hide?"hide":""}><img alt="" src={ logoUrl }/></div>
			<div id="menu">
				<ul>
					{ listItems }
				</ul>
			</div>
		</div>
		);
	}
}

export default HeaderContent;

