import React, { Component } from "react"
import "../css/footer.css"
import qrcodeUrl from '../images/home/qrcode.png'

class FootContent extends Component{
	constructor(props){
		super(props)
		this.state = {
			qrcode_hide: false,
		};
	}

	changeMenuActive = (e) => {
		const {changeMenu} = this.props;
		const active_index = e.target.getAttribute('data-index')
		changeMenu(active_index)
	}

	componentDidMount(){
		if(document.body.clientWidth <= 737){
			this.setState({
				qrcode_hide: true
			})
		}else{
			this.setState({
				qrcode_hide: false	
			})
		}
		window.addEventListener('resize', this.handleResize.bind(this))
	}
	handleResize = (e) => {
		if(document.body.clientWidth <= 737){
			this.setState({
				qrcode_hide: true
			})
		}else{
			this.setState({
				qrcode_hide: false	
			})
		}
	}
	render(){
		const {menu_list} = this.props;
		const listItems = menu_list.map((numbers, keys) =>
			<span key={keys}>
				<div className={keys===0?"hide":"line_block"}>|</div>	
				<div onClick={this.changeMenuActive} data-index={keys} className="line_block hand_point">{numbers}</div>
			</span>
		);

		return(
			<div id="footer">
				<div id="footer_left">
					<div id="footer_menu">
						{ listItems }
					</div>
					<div className="font_small">地址：广州市天河区华夏路28号富力盈信大夏1611室</div>
					<div className="font_small">客服电话：13042053109</div>
					<div className="font_small">电子邮箱：feiyuzu2021@163.com</div>
					<div className="font_small">@广州盈信云信息科技有限公司 | <a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2020075113号</a></div>
				</div>
				<div className={this.state.qrcode_hide?"hide":""} id="footer_right">
					<img alt="" src={ qrcodeUrl }/>
				</div>
			</div>
			);
	}
}

export default FootContent;