import './App.css';
import CommonContent from './component/Common';
function App() {
  	return (
    	<div className="App">
        	<CommonContent />
    	</div>
  	);
}

export default App;
